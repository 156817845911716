<template>
  <v-container class="home">
    <img src="../assets/IconEcon.png" alt="Logo" class="img" />
    <h1>Bem vindo ao sistema de agendamentos da clínica ECON</h1>
    <h2 style="color:gray">Escolha o serviço que deseja realizar o agendamento</h2>
    <div style="display: flex; justify-content:center;margin-top: 2rem">
      <v-btn class="btn" to="selecionaExame" color="blue" style="margin-right:2rem;color:white">
        Exame
      </v-btn>
      <v-btn class="btn" color="green" to="selecionaMedico" style="color:white">   
        Consulta
      </v-btn>
    </div>
    
  </v-container>
</template>

<script>
export default {
  name: "Home",
  mounted(){
    localStorage.removeItem("dados");
    localStorage.removeItem("data");
    localStorage.removeItem("especialidade");
    localStorage.removeItem("idProfissional");
    localStorage.removeItem("profissional");
    localStorage.removeItem("horario");
    localStorage.removeItem("idProcedimento");
  }
};
</script>
<style lang="sass" >
.home
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  text-align: center

  h1
    margin-bottom: 20px

  .img
    width: 100px
    margin-bottom: 15px
</style>